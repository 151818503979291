<template>
  <e-charts
    ref="line"
    auto-resize
    autoresize
    :options="chart"
    theme="theme-color"
  />
</template>

<script>
import { mapGetters } from "vuex";
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";
import theme from "./theme.json";
ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    ECharts,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      bigheightScreen: "app/bigheightScreen",
      mediumheightScreen: "app/mediumheightScreen",
      allSizeOfScreen: "app/allSizeOfScreen",
    }),
  },
  data() {
    return {
      chart: {
        grid: {
          width: "95%",
          left: "15px",
          right: "15px",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        yAxis: [
          {
            boundaryGap: true,
            data: this.optionData.xAxisData,
          },
        ],
        xAxis: {
          type: "value",
          splitLine: { show: true },
          stepSize: 50,
          height: 500,
        },
        // Data of Graphics
        series: this.optionData.series,
      },
    };
  },
};
</script>

<style>
.echarts {
  width: 100% !important;
}
</style>