<template>
  <div>
    <header-slot> </header-slot>
    <b-row>
      <b-col cols="2" class="text-center">
        <b-card
          v-if="[1, 2].includes(currentUser.role_id)"
          class="cursor-pointer"
          :class="tabActive == 1 ? 'bg-dash-bu-pf' : ''"
          @click="tabActive = 1"
          ><h5 class="text-bold">PROFIT AND LOSS</h5></b-card
        >
        <b-card
          class="cursor-pointer"
          :class="tabActive == 2 ? 'bg-dash-bu-payment' : ''"
          @click="tabActive = 2"
        >
          <h5 class="text-bold">PAYMENT REPORT</h5>
        </b-card>
        <b-card
          class="cursor-pointer"
          :class="tabActive == 3 ? 'bg-dash-bu-clients' : ''"
          @click="tabActive = 3"
        >
          <h5 class="text-bold">CLIENTS</h5></b-card
        >

        <b-card
          class="cursor-pointer"
          :class="tabActive == 4 ? 'bg-dash-bu-charges' : ''"
          @click="tabActive = 4"
          ><h5 class="text-bold">ADD CHARGES</h5></b-card
        >
        <b-card
          class="cursor-pointer"
          :class="tabActive == 5 ? 'bg-dash-bu-charges' : ''"
          @click="tabActive = 5"
          ><h5 class="text-bold">SALES MADE</h5></b-card
        >
      </b-col>
      <b-col cols="10">
        <graphic-profit-and-loss v-if="tabActive == 1" />
        <graphic-payment v-if="tabActive == 2" />
        <graphic-clients v-if="tabActive == 3" />
        <graphic-charges v-if="tabActive == 4" />
        <graphic-sales-made v-if="tabActive == 5" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <b-card>
          <div class="d-flex justify-content-end align-items-center px-1">
            <v-select
              v-model="selectYear"
              :reduce="(year) => year.id"
              :options="years"
              :selectable="(option) => option.selectable === true"
              :clearable="false"
              label="name"
              style="width: 150px"
              class="mr-1"
              @input="refreshDashboard()"
            />

            <v-select
              v-model="selectMonth"
              :reduce="(month) => month.id"
              :options="months"
              :clearable="false"
              label="name"
              style="width: 190px"
              @input="refreshDashboard()"
            />
          </div>
          <automatic-client-payments
            :key="keyAutomaticClientPayments"
            :class-names="'m-0'"
            :no-body="true"
            :select-year="selectYear"
            :select-month="selectMonth"
            :program-id="programId"
          />
        </b-card>
      </b-col>
      <b-col cols="6" v-if="isCeo || isChief || isSupervisor">
        <call-analysis
          ref="callAnalysis"
          :select-year="selectYear"
          :select-month="selectMonth"
          :module-id="3"
        >
          <template #calendar>
            <div class="d-flex justify-content-end align-items-center py-1">
              <v-select
                v-model="selectYear"
                :reduce="(year) => year.id"
                :options="years"
                :selectable="(option) => option.selectable === true"
                :clearable="false"
                label="name"
                style="width: 150px"
                class="mr-1"
              />

              <v-select
                v-model="selectMonth"
                :reduce="(month) => month.id"
                :options="months"
                :clearable="false"
                label="name"
                style="width: 190px"
              />
            </div>
          </template>
        </call-analysis>
      </b-col>
      <b-col cols="6">
        <DepartmentExpenses />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import GraphicPayment from "./components/GraphicPayment.vue";
import GraphicClients from "./components/GraphicClientsByStatus.vue";
import GraphicCharges from "./components/GraphicCharges.vue";
import GraphicSalesMade from "./components/GraphicSalesMade.vue";
import GraphicProfitAndLoss from "./components/GraphicProfitAndLoss.vue";
import { mapGetters } from "vuex";
import AutomaticClientPayments from "@/views/commons/components/payments/views/components/AutomaticClientPayments.vue";
import CallAnalysis from "@/views/ceo/dashboard/CallAnalysis.vue";
import DepartmentExpenses from "@/views/commons/expenses/components/department/DepartmentExpenses.vue";

export default {
  components: {
    GraphicPayment,
    GraphicClients,
    GraphicCharges,
    GraphicSalesMade,
    GraphicProfitAndLoss,
    AutomaticClientPayments,
    CallAnalysis,
    DepartmentExpenses,
  },
  data() {
    return {
      payments: {},
      key: 0,
      paymentsOptions: {
        from: new Date(new Date().setDate("05")),
        to: new Date(new Date().setDate("30")),
      },
      tabActive: 0,

      keyAutomaticClientPayments: 0,
      selectMonth: null,
      selectYear: null,
      optionsMonth: [
        { id: 1, name: "January" },
        { id: 2, name: "February" },
        { id: 3, name: "March" },
        { id: 4, name: "April" },
        { id: 5, name: "May" },
        { id: 6, name: "June" },
        { id: 7, name: "July" },
        { id: 8, name: "August" },
        { id: 9, name: "September" },
        { id: 10, name: "October" },
        { id: 11, name: "November" },
        { id: 12, name: "December" },
      ],
      optionsYears: [],
      today: new Date(),
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    programId() {
      return this.$route.matched[0].meta.program;
    },
    months() {
      if (this.selectYear === this.today.getFullYear()) {
        return this.optionsMonth.filter(
          (month) => month.id <= this.today.getMonth() + 1
        );
      }
      return this.optionsMonth;
    },
    years() {
      // Reset selectable mode for all years
      this.optionsYears.forEach((year) => {
        year.selectable = true;
      });

      // Disable future years if the selected month is greater than the current month
      const currentMonth = this.today.getMonth() + 1;
      if (this.selectMonth > currentMonth) {
        const lastYearIndex = this.optionsYears.length - 1;
        this.optionsYears[lastYearIndex].selectable = false;
      }

      return this.optionsYears;
    },

    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  created() {
    this.tabActive = [1, 2].includes(this.currentUser.role_id) ? 1 : 2;
    this.generateYears();
    this.currentMonth();
  },

  methods: {
    generateYears() {
      const today = new Date();
      const currentYear = today.getFullYear();
      const beginningYear = 2023;
      this.optionsYears = [];

      for (let i = beginningYear; i <= currentYear; i++) {
        this.optionsYears.push({ id: i, name: i, selectable: true });
      }

      this.selectYear = currentYear;
    },
    currentMonth() {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      this.selectMonth = currentMonth;
    },
    refreshDashboard() {
      this.keyAutomaticClientPayments++;
    },
  },
};
</script>
<style scoped>
.text-bold {
  font-weight: bold !important;
}
.bg-dash-bu-payment {
  background: linear-gradient(
    90deg,
    rgb(0, 144, 231) 0%,
    rgb(137, 196, 245) 100%
  ) !important;
}
.bg-dash-bu-payment h5 {
  color: #fff !important;
}
.bg-dash-bu-clients {
  background: linear-gradient(
    90deg,
    rgb(0, 144, 231) 0%,
    rgb(98, 240, 183) 100%
  ) !important;
}
.bg-dash-bu-clients h5 {
  color: #fff !important;
}

.bg-dash-bu-pf {
  background: linear-gradient(90deg, #0071b2 0%, #008f80 100%) !important;
}

.bg-dash-bu-pf h5 {
  color: white !important;
}

.bg-dash-bu-charges {
  background: linear-gradient(
    90deg,
    rgb(0, 144, 231) 0%,
    rgb(0, 144, 231) 100%
  ) !important;
}
.bg-dash-bu-charges h5 {
  color: #fff !important;
}
</style>
