<template>
  <b-card>
    <b-row>
      <b-col>
        <b-form-select
          v-model="year"
          :options="years"
          @change="getReportClients"
        ></b-form-select>
      </b-col>
      <b-col>
        <b-button variant="primary" @click="refreshData()">
          <feather-icon icon="RefreshCcwIcon" /> REFRESH</b-button
        >
      </b-col>
      <b-col></b-col> </b-row
    ><line-chart :optionData="report" :key="key" />
    <list-series :series="report.series"></list-series>
  </b-card>
</template>
<script>
import dashboradService from "@/views/business/views/dashboard/services/dashboard.services";
import LineChart from "../types/Line.vue";
import listSeries from "./ListSeries.vue";
export default {
  components: {
    LineChart,
    listSeries,
  },
  data() {
    return {
      report: { xAxisData: [], series: [] },
      key: 0,
      years: [],
      year: new Date().getFullYear(),
    };
  },
  created() {
    this.setYears();
    this.getReportClients();
  },
  methods: {
    setYears() {
      let currentYear = new Date().getFullYear();
      for (let i = 2018; i <= currentYear; i++) {
        this.years.push({ value: i, text: i });
      }
    },
    async getReportClients() {
      this.report = { xAxisData: [], series: [] };
      try {
        this.addPreloader();
        const params = { year: this.year };
        const data = await dashboradService.getClientsByStatus(params);
        let new_ = [];
        let active = [];
        let hold = [];
        let transition = [];
        data.forEach((element) => {
          this.report.xAxisData.push(element.year_month);
          new_.push(element.new);
          active.push(element.active);
          hold.push(element.hold);
          transition.push(element.transition);
        });
        for (let i = 1; i <= 4; i++) {
          this.report.series.push({
            type: "line",
            name:
              i == 1
                ? "New"
                : i == 2
                ? "Active"
                : i == 3
                ? "Hold"
                : "Transition",
            center: ["50%", "50%"],
            data: i == 1 ? new_ : i == 2 ? active : i == 3 ? hold : transition,
            color:
              i == 1
                ? "#62F0B7"
                : i == 2
                ? "#0042E7"
                : i == 3
                ? "#ff0000"
                : "#0090e7",
          });
        }
        this.key += 1;
        this.removePreloader();
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },
    async refreshData() {
      this.addPreloader();
      await dashboradService.generateDataClientsByStatusReport();
      this.getReportClients();
    },
  },
};
</script>