import moment from "moment";
export default [
  {
    text: "This Week",
    start: moment().startOf("isoWeek").format("YYYY-MM-DD"),
    to: moment().endOf("isoWeek").format("YYYY-MM-DD"),
    value: 1,
  },
  {
    text: "This Week to date",
    start: moment().startOf("isoWeek").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
    value: 2,
  },
  {
    text: "This Month",
    start: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().endOf("month").format("YYYY-MM-DD"),
    value: 3,
  },
  {
    text: "This Month to date",
    start: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
    value: 4,
  },
  {
    text: "This Quarter",
    start: moment().subtract(3, "month").startOf("month").format("YYYY-MM-DD"),
    to: moment().endOf("month").format("YYYY-MM-DD"),
    value: 5,
  },
  {
    text: "This Quarter to date",
    start: moment().subtract(3, "month").startOf("month").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
    value: 6,
  },
  {
    text: "This Year",
    start: moment(moment().format("YYYY") + "-01-01").format("YYYY-MM-DD"),
    to: moment(moment().format("YYYY") + "-12-31").format("YYYY-MM-DD"),
    value: 7,
  },
];
