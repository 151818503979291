import { amgApi } from "@/service/axios";
class Dashboard {
  async getPaymentAutomaticReport(params) {
    try {
      const { data } = await amgApi.post(
        "/business/get-payment-automatic-report",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getClientsByStatus(params) {
    try {
      const { data } = await amgApi.post(
        "/business/get-clients-by-status",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getReportChargesDashboard(params) {
    try {
      const { data } = await amgApi.post(
        "/business/get-report-charges",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getReportSalesMade(params) {
    try {
      const { data } = await amgApi.post(
        "/business/get-report-sales-made",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getReportProfitAndLoss(params) {
    try {
      const { data } = await amgApi.post(
        "/business/get-report-profit-and-loss",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async refreshdataPayment() {
    try {
      await amgApi.post("/business/generate-data-payment-report");
    } catch (error) {
      throw error;
    }
  }
  async generateDataClientsByStatusReport() {
    try {
      await amgApi.post("/business/generate-data-clients-by-status-report");
    } catch (error) {
      throw error;
    }
  }
}
export default new Dashboard();
