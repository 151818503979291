<template>
  <b-card>
    <b-row>
      <b-col>
        <b-form-datepicker
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          locale="en"
          v-model="filter.from"
        ></b-form-datepicker>
      </b-col>
      <b-col>
        <b-form-datepicker
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          locale="en"
          v-model="filter.to"
        ></b-form-datepicker>
      </b-col>
      <b-col>
        <b-select :options="rangeFilter" v-model="rangeSelected"></b-select>
      </b-col>
      <b-col>
        <b-button variant="primary" @click="getReportCharges()">
          <feather-icon icon="SearchIcon" />
          SEARCH</b-button
        >
      </b-col>
      <b-col></b-col>
    </b-row>
    <line-chart :optionData="report" :key="key" />
    <list-series :series="report.series"></list-series
  ></b-card>
</template>
<script>
import dashboradService from "@/views/business/views/dashboard/services/dashboard.services";
import LineChart from "../types/Line.vue";
import moment from "moment";
import dateRange from "@/views/business/views/dashboard/dateRange.data";
import listSeries from "./ListSeries.vue";
export default {
  components: {
    LineChart,
    listSeries,
  },
  data() {
    return {
      report: { xAxisData: [], series: [] },
      key: 0,
      years: [],
      rangeFilter: dateRange,
      rangeSelected: 1,
      filter: {
        from: moment().startOf("isoWeek").format("YYYY-MM-DD"),
        to: moment().endOf("isoWeek").format("YYYY-MM-DD"),
      },
    };
  },
  created() {
    this.getReportCharges();
  },
  methods: {
    async getReportCharges() {
      this.addPreloader();
      this.report = { xAxisData: [], series: [] };
      try {
        const params = { from: this.filter.from, to: this.filter.to };
        const data = await dashboradService.getReportProfitAndLoss(params);
        let profit = [];
        let loss = [];
        data.dates.forEach((element) => {
          this.report.xAxisData.push(element.date);
        });
        data.profit.forEach((element) => {
          profit.push(element.count);
        });
        data.loss.forEach((element) => {
          loss.push(element.count);
        });
        for (let i = 1; i <= 2; i++) {
          this.report.series.push({
            type: "line",
            name: i == 1 ? "profit $" : "loss $",
            center: ["50%", "50%"],
            data: i == 1 ? profit : loss,
            color: i == 1 ? "#0071B2" : "#008F80",
          });
        }
        this.key += 1;
        this.removePreloader();
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },
  },
  watch: {
    rangeSelected(newVal) {
      this.rangeFilter.forEach((element) => {
        if (element.value == newVal) {
          this.filter.from = element.start;
          this.filter.to = element.to;
        }
      });
    },
  },
};
</script>