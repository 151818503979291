<template>
  <b-card>
    <b-row
      ><b-col sm="3">
        <b-form-select v-model="paymentsOptions.month">
          <b-form-select-option
            :value="item.value"
            v-for="(item, index) in months"
            :key="index"
            :hidden="hiddenMonthByYear(item)"
            >{{ item.text }}</b-form-select-option
          >
        </b-form-select>
      </b-col>
      <b-col sm="3">
        <b-form-select
          v-model="paymentsOptions.year"
          :options="years"
        ></b-form-select>
      </b-col>
      <b-col sm="2">
        <b-button variant="primary" @click="getReportPaymets()">
          <feather-icon icon="SearchIcon" />
          SEARCH</b-button
        >
      </b-col>
    </b-row>
    <graphic :optionData="payments" :key="key"></graphic>
    <list-series :series="payments.series"></list-series>
  </b-card>
</template>
<script>
import Graphic from "../types/Graphic.vue";
import dashboradService from "@/views/business/views/dashboard/services/dashboard.services";
import listSeries from "./ListSeries.vue";
export default {
  data() {
    return {
      payments: {},
      key: 0,
      years: [],
      paymentsOptions: {
        month: parseInt(new Date().getMonth()) + 1,
        year: new Date().getFullYear(),
      },
      months: [
        {
          value: 1,
          text: "January",
        },
        {
          value: 2,
          text: "February",
        },
        {
          value: 3,
          text: "March",
        },
        {
          value: 4,
          text: "April",
        },
        {
          value: 5,
          text: "May",
        },
        {
          value: 6,
          text: "June",
        },
        {
          value: 7,
          text: "July",
        },
        {
          value: 8,
          text: "August",
        },
        {
          value: 9,
          text: "September",
        },
        {
          value: 10,
          text: "October",
        },
        {
          value: 11,
          text: "November",
        },
        {
          value: 12,
          text: "December",
        },
      ],
    };
  },
  components: {
    Graphic,
    listSeries,
  },
  created() {
    this.getReportPaymets();
    this.setYears();
  },
  methods: {
    setYears() {
      let currentYear = new Date().getFullYear();
      for (let i = 2018; i <= currentYear; i++) {
        this.years.push({ value: i, text: i });
      }
    },
    dateDisabledFrom(ymd, date) {
      const day = date.getDate();
      return day != 5;
    },
    dateDisabledTo(ymd, date) {
      const day = date.getDate();
      let dayDisabled = day != 30;
      let countDays = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      ).getDate();
      if (date.getMonth() == 1) {
        dayDisabled = day != countDays;
      }
      return dayDisabled;
    },
    async getReportPaymets() {
      this.addPreloader();
      try {
        const params = {
          year: this.paymentsOptions.year,
          month: this.paymentsOptions.month,
        };
        const data = await dashboradService.getPaymentAutomaticReport(params);
        let xAxisData = [];
        let expected = [];
        let payments = [];
        data.forEach((element) => {
          xAxisData.push((element.day < 10 ? "0" : "") + element.day + "TH");
          expected.push(element.expected);
          payments.push(element.payments);
        });
        let array = {
          xAxisData: xAxisData,
          series: [
            {
              type: "bar",
              name: "expected #",
              center: ["50%", "50%"],
              data: expected,
              color: "#0090e7",
            },
            {
              type: "bar",
              name: "payment #",
              center: ["50%", "50%"],
              data: payments,
              color: "#8f5fe8",
            },
          ],
        };
        this.payments = array;
        this.key += 1;
        this.removePreloader();
      } catch (error) {
        console.log(error);
      }
    },
    async refreshData() {
      this.addPreloader();
      await dashboradService.refreshdataPayment();
      this.getReportPaymets();
    },

    hiddenMonthByYear(item) {
      if (this.paymentsOptions.year == 2018) {
        if (item.value != 12) {
          this.paymentsOptions.month = 12;
          return true;
        }
      }
      return false;
    },
  },
  computed: {
    minDate() {
      return new Date(new Date().setDate("05"));
    },
  },
};
</script>