<template>
  <b-container style="margin-top: -25px">
    <b-row>
      <div v-for="(item, index) in series" :key="index" :lg="cols">
        <div style="display: flex; align-items: center" class="ml-2">
          <div
            :style="`width: 10px; height: 10px; background: ${item.color}; float: left`"
          ></div>
          <span style="margin-left: 2.5px">{{
            item.name.replace("$", "").replace("#", "")
          }}</span>
        </div>
      </div>
    </b-row>
  </b-container>
</template>
<script>
export default {
  props: {
    series: Array,
    cols: { type: Number, default: 2 },
  },
};
</script>