<template>
  <b-card>
    <b-row>
      <b-col>
        <b-form-datepicker
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          locale="en"
          v-model="filter.from"
        ></b-form-datepicker>
      </b-col>
      <b-col>
        <b-form-datepicker
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          locale="en"
          v-model="filter.to"
        ></b-form-datepicker>
      </b-col>
      <b-col>
        <b-select :options="rangeFilter" v-model="rangeSelected"></b-select>
      </b-col>
      <b-col>
        <b-select v-model="chargeSelected">
          <b-select-option :value="null">All programs</b-select-option>
          <b-select-option
            v-for="(item, index) in charges"
            :key="index"
            :value="item.id"
            >{{ item.description }}</b-select-option
          >
        </b-select>
      </b-col>
      <b-col>
        <b-button variant="primary" @click="getReportCharges()">
          <feather-icon icon="SearchIcon" />
          SEARCH</b-button
        >
      </b-col>
      <b-col></b-col>
    </b-row>
    <line-chart :optionData="report" :key="key" />
    <list-series :series="report.series"></list-series>
  </b-card>
</template>
<script>
import dashboradService from "@/views/business/views/dashboard/services/dashboard.services";
import LineChart from "../types/Line.vue";
import dateRange from "@/views/business/views/dashboard/dateRange.data";
import moment from "moment";
import listSeries from "./ListSeries.vue";
export default {
  components: {
    LineChart,
    listSeries,
  },
  data() {
    return {
      report: { xAxisData: [], series: [] },
      key: 0,
      years: [],
      rangeFilter: dateRange,
      rangeSelected: 1,
      charges: [],
      chargeSelected: null,
      filter: {
        from: moment().startOf("isoWeek").format("YYYY-MM-DD"),
        to: moment().endOf("isoWeek").format("YYYY-MM-DD"),
      },
    };
  },
  created() {
    this.getReportCharges();
  },
  methods: {
    async getReportCharges() {
      this.report = { xAxisData: [], series: [] };
      try {
        this.addPreloader();
        const params = {
          from: this.filter.from,
          to: this.filter.to,
          charge_id: this.chargeSelected,
        };
        const data = await dashboradService.getReportChargesDashboard(params);
        data.dates.forEach((element) => {
          this.report.xAxisData.push(element.date);
        });
        // ********
        this.charges = data.charges;
        // ********
        data.data.forEach((element) => {
          let data = [];
          element.date.forEach((elementDate) => {
            data.push(elementDate.count);
          });
          this.report.series.push({
            type: "line",
            name: element.charge,
            id: element.id,
            display: "none",
            center: ["50%", "50%"],
            data: data,
            color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
          });
        });
        this.key += 1;
        this.removePreloader();
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },
  },
  watch: {
    rangeSelected(newVal) {
      this.rangeFilter.forEach((element) => {
        if (element.value == newVal) {
          this.filter.from = element.start;
          this.filter.to = element.to;
        }
      });
    },
  },
};
</script>